import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'

const OurSolutions = () => {
    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="star" /> 
                        Our Solutions
                    </span>
                    <h2>Why you should choose us ?</h2>
                    <p>We have made the life of our customers easier on the cloud. Their application lifecycle is now fully automated with minimum human intervention. And they can focus on the product rather than focusing on production.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-rocket"></i>
                            </div>
                            <h3>
                                Fully Automated
                                {/* <Link to="#">
                                    Fully Automated
                                </Link> */}
                            </h3>
                            <p>We believe in complete automation for the entire application lifecycle right from spinning up your first server to deploying your code in production.</p>

                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-laptop"></i>
                            </div>

                            <h3>
                                Well Architected
                                {/* <Link to="#">
                                    Well Architected
                                </Link> */}
                            </h3>

                            <p>We help our customers in making right choice of technologies out there suited for the task at hand. These ensures that the applications are scalable, reliable and highly available.</p>
                            
                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6 offset-lg-0 offset-sm-3">
                        <div className="single-solutions-box">
                            <div className="icon">
                                <i className="flaticon-money"></i>
                            </div>

                            <h3>
                                Secure and Cost Efficient
                                {/* <Link to="#">
                                    
                                </Link> */}
                            </h3> 

                            <p>We try to keep security and cost efficiency at the forefront of everything we try do.</p>
                            
                            {/* <Link to="/service-details" className="view-details-btn">
                                View Details
                            </Link> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurSolutions;