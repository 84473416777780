import React from 'react'
import service1 from '../../assets/images/services/service1.png'
import service2 from '../../assets/images/services/service2.png'
import starIcon from '../../assets/images/star-icon.png'

const OurServices = () => {
    return (
        <React.Fragment>
            {/* Service Left Image Style */}
            <div className="about-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-img">
                                <img src={service1} alt="service" />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="about-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Devops Services</h2>
                                    <p>We take care of running your application so that you can focus on features for your customers.</p>
                                    <ul className="about-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Infrastructure as Code
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Code Deployment Pipelines
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Containerization
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Kubernetes
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Github Actions
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Terraform
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cloudformation
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Logging and Monitoring
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Left Image Style */}

            {/* Service Right Image Style */}
            <div className="our-mission-area pb-100">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-content">
                                <div className="content">
                                    <span className="sub-title">
                                        <img src={starIcon} alt="icon" /> Services
                                    </span>
                                    
                                    <h2>Cloud Services</h2>
                                    <p>It can get overwhelming with the vast amount of things to take care of. We are here to help.</p>

                                    <ul className="our-mission-list mb-0">
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Choosing the Stack.
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Cost Optmization
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Securing your Infrastructure
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Migrating Services
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Data Backups
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Availability
                                        </li>
                                        <li>
                                            <i className="flaticon-tick"></i>
                                            Architecture Review
                                        </li>
                                        {/* <li>
                                            <i className="flaticon-tick"></i>
                                            Print Ready Design
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="our-mission-image">
                                <img src={service2} alt="service" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Service Right Image Style */}
        </React.Fragment>
    )
}

export default OurServices;